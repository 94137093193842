<script setup lang="ts">
import { cn } from '@wision/ui/src/lib/utils';
import { PinInputInput, type PinInputInputProps, useForwardProps } from 'reka-ui';
import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<PinInputInputProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;
  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <PinInputInput
    v-bind="forwardedProps"
    :class="cn('bg-transparent relative text-center focus:outline-none focus:ring-2 focus:ring-gray-950 focus:relative focus:z-10 flex h-10 w-10 items-center justify-center border-y border-r border-gray-200 text-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md dark:focus:ring-gray-300 dark:border-gray-600', props.class)"
  />
</template>
