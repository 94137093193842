<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@tanstack/vue-query';
import { toast } from 'vue3-toastify';
import {
  AvatarIcon,
  ArrowIcon,
  Direction,
} from '@wision/ui';
import EmailLoginForm from './EmailLoginForm.vue';
import VerifyMFA from './VerifyMFA.vue';
import SelectAccount from './SelectAccount.vue';
import { useSupabaseAuth } from '@/composables/useSupabaseAuth';

type LoginStep = 'start' | 'login' | 'mfa' | 'selectCustomer'

const { t } = useI18n();
const currentStep = ref<LoginStep>('start');
const mouseX = ref<number>(0);
const mouseY = ref<number>(0);
const angle = ref<number>(180);
const spinning = ref(false);
const customers = ref<{ id: string; name: string, xtoolId: number }[]>();
const userFullName = ref<string>();
const pageY = ref(0);
const height = ref(0);
const spinIntervalId = ref();
const config = useRuntimeConfig();
const lightOpacity = computed(
  () =>
    (Math.abs(mouseX.value) > Math.abs(mouseY.value)
      ? Math.abs(mouseX.value)
      : Math.abs(mouseY.value)) * 100
);

const { $supabase } = useNuxtApp();
const auth = useSupabaseAuth();

const loginCard = ref<HTMLElement>();

const cardTransformStyle = computed(() => ({
  transform:
    'rotateX(' +
    -mouseY.value * (5 * 5) +
    'deg) rotateY(' +
    mouseX.value * (5 * 5) +
    'deg)',
}));

const layerOneTransformStyle = computed(() => ({
  transform:
    'translateX(' +
    mouseX.value * 5 +
    'px) translateY(' +
    mouseY.value * 5 +
    'px)',
}));

const lightBackgroundStyle = computed(() => ({
  background: `linear-gradient(${
    angle.value
  }deg, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) ${
    lightOpacity.value < 0 ? lightOpacity.value * -1 : lightOpacity.value
  }%)`,
}));

const releaseInfo =
  config.public.wisionReleaseName || config.public.wisionRelease;

const loginMutation = useMutation({
  mutationFn: async (params: { email: string; password: string }) => {
    const result = await auth.login(params.email, params.password);

    if (!result.ok) {
      toast(t('toast.invalidcredentials'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      });

      throw result.error;
    }

    return result.value;
  },
  onSuccess: async ([user, mfa]) => {
    const jwt = jwtDecode(user.session.access_token) as {
      org_details: { id: string; name: string; xtool_id: number }[];
      name: string;
    };

    const { data, error } = await $supabase.rpc('get_user_access', {
      user_id: user.user.id
    });

    if (error) window.location.href = '/';

    if (data !== null && data.length > 1) {
      customers.value = data
        .filter((customer) => customer.name && customer.xtool_id)
        .map((customer) => ({
          id: customer.id,
          name: customer.name,
          xtoolId: customer.xtool_id
        }));

      userFullName.value = jwt.name;

      currentStep.value = mfa ? 'mfa' : 'selectCustomer';
    } else {
      window.location.href = '/';
    }
  },
  onError: () => {
    toast(t('toast.invalidcredentials'), {
      autoClose: 2000,
      type: 'error',
      theme: 'dark',
    });
  },
});

const handleMouseOver = (e: MouseEvent) => {
  const w = window.innerWidth;
  const h = window.innerHeight;

  height.value = h;
  pageY.value = e.pageY;

  const xVal = 0.5 - e.pageX / w;
  const yVal = 0.5 - e.pageY / h;

  const dy = e.pageY - h / 2;
  const dx = e.pageX - w / 2;

  const theta = Math.atan2(dy, dx);
  angle.value = (theta * 180) / Math.PI - 90;

  mouseX.value = xVal;
  mouseY.value = yVal;
};

onMounted(() => {
  window.addEventListener('mousemove', handleMouseOver);
});

const handleShowEmailLoginForm = (step: LoginStep) => {
  if (spinning.value) return;
  spinning.value = true;
  currentStep.value = step;
  window.removeEventListener('mousemove', handleMouseOver);
  spinIntervalId.value = setInterval(() => {
    if (mouseX.value > 7.2) {
      clearInterval(spinIntervalId.value);
      window.addEventListener('mousemove', handleMouseOver);
      mouseX.value = 0;
      spinning.value = false;
    }

    mouseX.value = mouseX.value + 0.1;
  }, 5);
};

const handleLogin = async (email: string, password: string) =>
  loginMutation.mutate({
    email,
    password
  });

const handleMFAVerification = async (code: string) => {
  const result = await auth.verifyLoginFactor(code);

  if (!result.ok) {
    toast(t('mfa.mfafailed'), {
      autoClose: 2000,
      type: 'error',
      theme: 'dark',
    });

    return;
  }

  currentStep.value = 'selectCustomer';
};
</script>

<template>
  <video
    autoplay
    muted
    loop
    class="video"
  >
    <source
      src="/public/assets/wioniq_nodes_web.webm"
      type="video/mp4"
    >
  </video>

  <div
    class="flex items-center justify-center h-screen p-4 text-white font-mono"
  >
    <div class="w-96 sm:h-96 animated fadeIn z-30">
      <div
        ref="loginCard"
        :style="cardTransformStyle"
        class="p-8 bg-dark-widget rounded-lg h-[400px] w-full relative"
      >
        <div
          :style="lightBackgroundStyle"
          class="light"
        />
        <transition
          name="fade-fast"
          mode="out-in"
        >
          <div v-if="currentStep == 'start'">
            <div
              class="absolute top-[50%] translate-y-[-50%] w-full left-[50%] translate-x-[-50%] flex items-center flex-col"
            >
              <img
                :src="config.public.bigLogoUrl"
                width="200"
              >
              <p class="text-center text-gray-400 mt-5 text-sm w-[149px]">
                {{ $t('login.methods.select') }}
              </p>
            </div>
            <div
              class="flex items-center justify-center w-full gap-2 mt-2 absolute bottom-[48px] left-[50%] translate-x-[-50%] px-4"
            >
              <div
                class="grid grid-rows-[30px_20px] items-center rounded-lg hover:bg-gray-700 w-full justify-items-center cursor-pointer p-2"
                :style="layerOneTransformStyle"
                @click="handleShowEmailLoginForm('login')"
              >
                <AvatarIcon class="h-[30px] w-[30px]" />
                <p
                  class="text-sm text-center w-full text-gray-300 pt-2 truncate"
                >
                  {{ $t('login.methods.email') }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else-if="currentStep == 'login'"
            class="h-full flex items-center relative"
          >
            <ArrowIcon
              :direction="Direction.Left"
              class="absolute top-2 left-2 cursor-pointer"
              @click="handleShowEmailLoginForm('start')"
            />
            <EmailLoginForm
              :is-loading="loginMutation.isLoading.value"
              @login="handleLogin"
            />
          </div>
          <div
            v-else-if="currentStep == 'mfa'"
            class="h-full flex items-center relative"
          >
            <ArrowIcon
              :direction="Direction.Left"
              class="absolute top-2 left-2 cursor-pointer"
              @click="handleShowEmailLoginForm('login')"
            />
            <VerifyMFA @verify="handleMFAVerification" />
          </div>
          <SelectAccount
            v-else
            :user-full-name="userFullName ?? ''"
            :accounts="customers ?? []"
          />
        </transition>
        <div
          class="text-xs text-center text-gray-500 absolute bottom-2 left-[50%] translate-x-[-50%]"
        >
          {{ releaseInfo }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.light {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
