<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { PinInput, PinInputGroup, PinInputInput } from '@wision/ui';

const { t } = useI18n();
const code = ref<string[]>([]);
const pinInputRef = ref<InstanceType<typeof PinInput> | null>(null);
const config = useRuntimeConfig();

const emits = defineEmits<{
  (e: 'verify', code: string): void;
}>();

const handleVerify = () => {
  emits('verify', code.value.join(''));
};

onMounted(() => {
  nextTick(() => {
    const firstInput = document.getElementById('pin-input1');
    firstInput?.focus();
  });
});
</script>

<template>
  <div class="w-full">
    <div class="w-full flex flex-col items-center">
      <img
        :src="config.public.bigLogoUrl"
        width="200"
      >
    </div>
    <p class="text-center mt-5 text-gray-400">
      {{ t('mfa.entercode') }}
    </p>
    <div class="my-4 space-y-2 w-full">
      <div class="flex flex-col items-center w-full">
        <PinInput
          id="pin-input"
          ref="pinInputRef"
          v-model="code"
          placeholder="○"
          @complete="handleVerify"
        >
          <PinInputGroup>
            <PinInputInput
              v-for="(id, index) in 6"
              :id="'pin-input' + id"
              :key="id"
              :index="index"
            />
          </PinInputGroup>
        </PinInput>
      </div>
    </div>
  </div>
</template>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
</style>
